.jp-card.jp-card-safari.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-safari.jp-card-identified .jp-card-back:before {
  background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(
            255,
            255,
            255,
            0.2
          )
          70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    linear-gradient(
      -25deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.2) 70%,
      rgba(255, 255, 255, 0) 90%
    );
}

.jp-card.jp-card-ie-10.jp-card-flipped,
.jp-card.jp-card-ie-11.jp-card-flipped {
  -webkit-transform: 0deg;
  -moz-transform: 0deg;
  -ms-transform: 0deg;
  -o-transform: 0deg;
  transform: 0deg;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-front,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-front {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back:after,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back:after {
  left: 18%;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-cvc,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-cvc {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  left: 5%;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-shiny,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-shiny {
  left: 84%;
}
.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-shiny:after,
.jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-shiny:after {
  left: -480%;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.jp-card.jp-card-ie-10.jp-card-amex .jp-card-back,
.jp-card.jp-card-ie-11.jp-card-amex .jp-card-back {
  display: none;
}

.jp-card-logo {
  height: 36px;
  width: 60px;
  font-style: italic;
}
.jp-card-logo,
.jp-card-logo:before,
.jp-card-logo:after {
  box-sizing: border-box;
}

.jp-card-logo.jp-card-amex {
  text-transform: uppercase;
  font-size: 4px;
  font-weight: bold;
  color: white;
  background-image: repeating-radial-gradient(
    circle at center,
    #fff 1px,
    #999 2px
  );
  background-image: repeating-radial-gradient(
    circle at center,
    #fff 1px,
    #999 2px
  );
  border: 1px solid #eee;
}
.jp-card-logo.jp-card-amex:before,
.jp-card-logo.jp-card-amex:after {
  width: 28px;
  display: block;
  position: absolute;
  left: 16px;
}
.jp-card-logo.jp-card-amex:before {
  height: 28px;
  content: 'american';
  top: 3px;
  text-align: left;
  padding-left: 2px;
  padding-top: 11px;
  background: #267ac3;
}
.jp-card-logo.jp-card-amex:after {
  content: 'express';
  bottom: 11px;
  text-align: right;
  padding-right: 2px;
}

.jp-card.jp-card-amex.jp-card-flipped {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-amex.jp-card-identified .jp-card-back:before {
  background-color: #108168;
}

.jp-card.jp-card-amex.jp-card-identified
  .jp-card-front
  .jp-card-logo.jp-card-amex {
  opacity: 1;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front .jp-card-cvc {
  visibility: visible;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front:after {
  opacity: 1;
}

.jp-card-logo.jp-card-discover {
  background: #ff6600;
  color: #111;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 9px;
  letter-spacing: 0.03em;
  border: 1px solid #eee;
}
.jp-card-logo.jp-card-discover:before,
.jp-card-logo.jp-card-discover:after {
  content: ' ';
  display: block;
  position: absolute;
}
.jp-card-logo.jp-card-discover:before {
  background: white;
  width: 200px;
  height: 200px;
  border-radius: 200px;
  bottom: -5%;
  right: -80%;
  z-index: -1;
}
.jp-card-logo.jp-card-discover:after {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 10px;
  left: 27px;
  background-color: #ff6600;
  background-image: -webkit-radial-gradient(#ff6600, #fff);
  background-image: radial-gradient(#ff6600, #fff);
  content: 'network';
  font-size: 4px;
  line-height: 24px;
  text-indent: -7px;
}

.jp-card .jp-card-front .jp-card-logo.jp-card-discover {
  right: 12%;
  top: 18%;
}

.jp-card.jp-card-discover.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-discover.jp-card-identified .jp-card-back:before {
  background-color: #86b8cf;
}

.jp-card.jp-card-discover.jp-card-identified .jp-card-logo.jp-card-discover {
  opacity: 1;
}

.jp-card.jp-card-discover.jp-card-identified .jp-card-front:after {
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  transition: 400ms;
  content: ' ';
  display: block;
  background-color: #ff6600;
  background-image: -webkit-linear-gradient(#ff6600, #ffa366, #ff6600);
  background-image: linear-gradient(#ff6600, #ffa366, #ff6600);
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  left: 100%;
  top: 15%;
  margin-left: -25px;
  box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
}

.jp-card-logo.jp-card-visa {
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  margin-top: 5px;
}
.jp-card-logo.jp-card-visa:before,
.jp-card-logo.jp-card-visa:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 25%;
}
.jp-card-logo.jp-card-visa:before {
  position: absolute;
  left: -4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 6px 0;
  border-color: transparent #ffffff transparent transparent;
}

.jp-card.jp-card-visa.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-visa.jp-card-identified .jp-card-back:before {
  background-color: #191278;
}

.jp-card.jp-card-visa.jp-card-identified .jp-card-logo.jp-card-visa {
  opacity: 1;
  box-shadow: none;
}

.jp-card-logo.jp-card-visaelectron {
  background: white;
  text-transform: uppercase;
  color: #1a1876;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}
.jp-card-logo.jp-card-visaelectron:before,
.jp-card-logo.jp-card-visaelectron:after {
  content: ' ';
  display: block;
  width: 100%;
  height: 25%;
}
.jp-card-logo.jp-card-visaelectron:before {
  background: #1a1876;
}
.jp-card-logo.jp-card-visaelectron:after {
  background: #e79800;
}
.jp-card-logo.jp-card-visaelectron .elec {
  float: right;
  font-family: arial;
  font-size: 9px;
  margin-right: 1px;
  margin-top: -5px;
  text-transform: none;
}

.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-back:before {
  background-color: #191278;
}

.jp-card.jp-card-visaelectron.jp-card-identified
  .jp-card-logo.jp-card-visaelectron {
  opacity: 1;
}

.jp-card-logo.jp-card-mastercard {
  color: white;
  font-style: normal;
  text-transform: lowercase;
  font-weight: bold;
  text-align: center;
  font-size: 9px;
  line-height: 84px;
  z-index: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
}
.jp-card-logo.jp-card-mastercard:before,
.jp-card-logo.jp-card-mastercard:after {
  content: ' ';
  display: block;
  width: 36px;
  top: 0;
  position: absolute;
  height: 36px;
  border-radius: 18px;
}
.jp-card-logo.jp-card-mastercard:before {
  left: 0;
  background: #eb001b;
  z-index: -1;
  opacity: 0.9;
}
.jp-card-logo.jp-card-mastercard:after {
  right: 0;
  background: #ff5f00;
  z-index: -2;
}

.jp-card.jp-card-mastercard.jp-card-identified
  .jp-card-front
  .jp-card-logo.jp-card-mastercard,
.jp-card.jp-card-mastercard.jp-card-identified
  .jp-card-back
  .jp-card-logo.jp-card-mastercard {
  box-shadow: none;
}

.jp-card.jp-card-mastercard.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-mastercard.jp-card-identified .jp-card-back:before {
  background-color: #0061a8;
}

.jp-card.jp-card-mastercard.jp-card-identified
  .jp-card-logo.jp-card-mastercard {
  opacity: 1;
}

.jp-card-logo.jp-card-maestro {
  color: white;
  font-style: normal;
  text-transform: lowercase;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  line-height: 84px;
  z-index: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
}
.jp-card-logo.jp-card-maestro:before,
.jp-card-logo.jp-card-maestro:after {
  content: ' ';
  display: block;
  width: 36px;
  top: 0;
  position: absolute;
  height: 36px;
  border-radius: 18px;
}
.jp-card-logo.jp-card-maestro:before {
  left: 0;
  background: #eb001b;
  z-index: -2;
}
.jp-card-logo.jp-card-maestro:after {
  right: 0;
  background: #00a2e5;
  z-index: -1;
  opacity: 0.8;
}

.jp-card.jp-card-maestro.jp-card-identified
  .jp-card-front
  .jp-card-logo.jp-card-maestro,
.jp-card.jp-card-maestro.jp-card-identified
  .jp-card-back
  .jp-card-logo.jp-card-maestro {
  box-shadow: none;
}

.jp-card.jp-card-maestro.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-maestro.jp-card-identified .jp-card-back:before {
  background-color: #0b2c5f;
}

.jp-card.jp-card-maestro.jp-card-identified .jp-card-logo.jp-card-maestro {
  opacity: 1;
}

.jp-card-logo.jp-card-dankort {
  width: 60px;
  height: 36px;
  padding: 3px;
  border-radius: 8px;
  border: #000000 1px solid;
  background-color: #ffffff;
}
.jp-card-logo.jp-card-dankort .dk {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.jp-card-logo.jp-card-dankort .dk:before {
  background-color: #ed1c24;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
}
.jp-card-logo.jp-card-dankort .dk:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -7.7px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 10px 0;
  border-color: transparent #ed1c24 transparent transparent;
  z-index: 1;
}
.jp-card-logo.jp-card-dankort .d,
.jp-card-logo.jp-card-dankort .k {
  position: absolute;
  top: 50%;
  width: 50%;
  display: block;
  height: 15.4px;
  margin-top: -7.7px;
  background: white;
}
.jp-card-logo.jp-card-dankort .d {
  left: 0;
  border-radius: 0 8px 10px 0;
}
.jp-card-logo.jp-card-dankort .d:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background: #ed1c24;
  border-radius: 2px 4px 6px 0px;
  height: 5px;
  width: 7px;
  margin: -3px 0 0 -4px;
}
.jp-card-logo.jp-card-dankort .k {
  right: 0;
}
.jp-card-logo.jp-card-dankort .k:before,
.jp-card-logo.jp-card-dankort .k:after {
  content: '';
  position: absolute;
  right: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  margin-right: -1px;
}
.jp-card-logo.jp-card-dankort .k:before {
  top: 0;
  border-width: 8px 5px 0 0;
  border-color: #ed1c24 transparent transparent transparent;
}
.jp-card-logo.jp-card-dankort .k:after {
  bottom: 0;
  border-width: 0 5px 8px 0;
  border-color: transparent transparent #ed1c24 transparent;
}

.jp-card.jp-card-dankort.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-dankort.jp-card-identified .jp-card-back:before {
  background-color: #0055c7;
}

.jp-card.jp-card-dankort.jp-card-identified .jp-card-logo.jp-card-dankort {
  opacity: 1;
}

.jp-card-logo.jp-card-elo {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: black;
  color: white;
  text-align: center;
  text-transform: lowercase;
  font-size: 21px;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: bold;
  padding-top: 13px;
}
.jp-card-logo.jp-card-elo .e,
.jp-card-logo.jp-card-elo .l,
.jp-card-logo.jp-card-elo .o {
  display: inline-block;
  position: relative;
}
.jp-card-logo.jp-card-elo .e {
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
.jp-card-logo.jp-card-elo .o {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  right: 0;
  top: 7px;
  border-radius: 100%;
  background-image: -webkit-linear-gradient(yellow 50%, red 50%);
  background-image: linear-gradient(yellow 50%, red 50%);
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transform: rotate(40deg);
  text-indent: -9999px;
}
.jp-card-logo.jp-card-elo .o:before {
  content: '';
  position: absolute;
  width: 49%;
  height: 49%;
  background: black;
  border-radius: 100%;
  text-indent: -99999px;
  top: 25%;
  left: 25%;
}

.jp-card.jp-card-elo.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-elo.jp-card-identified .jp-card-back:before {
  background-color: #6f6969;
}

.jp-card.jp-card-elo.jp-card-identified .jp-card-logo.jp-card-elo {
  opacity: 1;
}

.jp-card-logo.jp-card-jcb {
  border-radius: 5px 0px 5px 0px;
  -moz-border-radius: 5px 0px 5px 0px;
  -webkit-border-radius: 5px 0px 5px 0px;
  background-color: white;
  font-style: normal;
  color: white;
  width: 50px;
  padding: 2px 0 0 2px;
}
.jp-card-logo.jp-card-jcb > div {
  width: 15px;
  margin-right: 1px;
  display: inline-block;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
  border-radius: 5px 0px 5px 0px;
  -moz-border-radius: 5px 0px 5px 0px;
  -webkit-border-radius: 5px 0px 5px 0px;
}
.jp-card-logo.jp-card-jcb > div:before,
.jp-card-logo.jp-card-jcb > div:after {
  content: ' ';
  display: block;
  height: 8px;
}
.jp-card-logo.jp-card-jcb > div.j {
  background-color: #000063;
  background-image: -webkit-linear-gradient(left, #000063, #008cff);
  background-image: linear-gradient(to right, #000063, #008cff);
}
.jp-card-logo.jp-card-jcb > div.c {
  background-color: #630000;
  background-image: -webkit-linear-gradient(left, #630000, #ff008d);
  background-image: linear-gradient(to right, #630000, #ff008d);
}
.jp-card-logo.jp-card-jcb > div.b {
  background-color: #006300;
  background-image: -webkit-linear-gradient(left, #006300, #00ff00);
  background-image: linear-gradient(to right, #006300, #00ff00);
}

.jp-card.jp-card-jcb.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-jcb.jp-card-identified .jp-card-back:before {
  background-color: #cb8000;
}

.jp-card.jp-card-jcb.jp-card-identified .jp-card-logo.jp-card-jcb {
  opacity: 1;
  box-shadow: none;
}

.jp-card-logo.jp-card-dinersclub {
  font-family: serif;
  height: 40px;
  width: 100px;
  color: white;
  font-size: 17px;
  font-style: normal;
  letter-spacing: 1px;
}
.jp-card-logo.jp-card-dinersclub::before,
.jp-card-logo.jp-card-dinersclub::after {
  display: block;
  position: relative;
}
.jp-card-logo.jp-card-dinersclub::before {
  content: 'Diners Club';
}
.jp-card-logo.jp-card-dinersclub::after {
  content: 'International';
  text-transform: uppercase;
  font-size: 0.6em;
}

.jp-card.jp-card-dinersclub .jp-card-front .jp-card-logo {
  box-shadow: none !important;
}

.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-back:before {
  background-color: #999;
}

.jp-card.jp-card-dinersclub.jp-card-identified
  .jp-card-logo.jp-card-dinersclub {
  opacity: 1;
}

.jp-card-container {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  width: 350px;
  max-width: 100%;
  height: 200px;
  margin: auto;
  z-index: 1;
  position: relative;
}

.jp-card {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 315px;
  border-radius: 10px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  -moz-transition: all 400ms linear;
  transition: all 400ms linear;
}
.jp-card > *,
.jp-card > *:before,
.jp-card > *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
}
.jp-card.jp-card-flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.jp-card .jp-card-front,
.jp-card .jp-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  -moz-transition: all 400ms linear;
  transition: all 400ms linear;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
  background: #ddd;
}
.jp-card .jp-card-front:before,
.jp-card .jp-card-back:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 10px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.jp-card .jp-card-front:after,
.jp-card .jp-card-back:after {
  content: ' ';
  display: block;
}
.jp-card .jp-card-front .jp-card-display,
.jp-card .jp-card-back .jp-card-display {
  color: white;
  font-weight: normal;
  opacity: 0.5;
  -webkit-transition: opacity 400ms linear;
  -moz-transition: opacity 400ms linear;
  transition: opacity 400ms linear;
}
.jp-card .jp-card-front .jp-card-display.jp-card-focused,
.jp-card .jp-card-back .jp-card-display.jp-card-focused {
  opacity: 1;
  font-weight: 700;
}
.jp-card .jp-card-front .jp-card-cvc,
.jp-card .jp-card-back .jp-card-cvc {
  font-family: 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  font-size: 14px;
}
.jp-card .jp-card-front .jp-card-shiny,
.jp-card .jp-card-back .jp-card-shiny {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  background: #ccc;
  position: relative;
}
.jp-card .jp-card-front .jp-card-shiny:before,
.jp-card .jp-card-back .jp-card-shiny:before {
  content: ' ';
  display: block;
  width: 70%;
  height: 60%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #d9d9d9;
  position: absolute;
  top: 20%;
}
.jp-card .jp-card-front .jp-card-logo {
  position: absolute;
  opacity: 0;
  right: 5%;
  top: 8%;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  transition: 400ms;
}
.jp-card .jp-card-front .jp-card-lower {
  width: 80%;
  position: absolute;
  left: 10%;
  bottom: 30px;
}
@media only screen and (max-width: 480px) {
  .jp-card .jp-card-front .jp-card-lower {
    width: 90%;
    left: 5%;
  }
}
.jp-card .jp-card-front .jp-card-lower .jp-card-cvc {
  visibility: hidden;
  float: right;
  position: relative;
  bottom: 5px;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-number {
  font-family: 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  font-size: 24px;
  clear: both;
  margin-bottom: 30px;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
  font-family: 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  letter-spacing: 0em;
  position: relative;
  float: right;
  width: 25%;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before,
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 7px;
  white-space: pre;
  display: block;
  opacity: 0.5;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before {
  content: attr(data-before);
  margin-bottom: 2px;
  font-size: 7px;
  text-transform: uppercase;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
  position: absolute;
  content: attr(data-after);
  text-align: right;
  right: 100%;
  margin-right: 5px;
  margin-top: 2px;
  bottom: 0;
}
.jp-card .jp-card-front .jp-card-lower .jp-card-name {
  text-transform: uppercase;
  font-family: 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  font-size: 20px;
  max-height: 45px;
  position: absolute;
  bottom: 0;
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jp-card .jp-card-back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.jp-card .jp-card-back .jp-card-bar {
  background-color: #444;
  background-image: -webkit-linear-gradient(#444, #333);
  background-image: linear-gradient(#444, #333);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 10%;
}
.jp-card .jp-card-back:after {
  content: ' ';
  display: block;
  background-color: #fff;
  background-image: -webkit-linear-gradient(#fff, #fff);
  background-image: linear-gradient(#fff, #fff);
  width: 80%;
  height: 16%;
  position: absolute;
  top: 40%;
  left: 2%;
}
.jp-card .jp-card-back .jp-card-cvc {
  position: absolute;
  top: 40%;
  left: 85%;
  -webkit-transition-delay: 600ms;
  -moz-transition-delay: 600ms;
  transition-delay: 600ms;
}
.jp-card .jp-card-back .jp-card-shiny {
  position: absolute;
  top: 66%;
  left: 2%;
}
.jp-card .jp-card-back .jp-card-shiny:after {
  content: 'This card has been issued by Jesse Pollak and is licensed for anyone to use anywhere for free. It comes with no warranty. For support issues, please visit: github.com/jessepollak/card.';
  position: absolute;
  left: 120%;
  top: 5%;
  color: white;
  font-size: 7px;
  width: 230px;
  opacity: 0.5;
}
.jp-card.jp-card-identified {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
.jp-card.jp-card-identified .jp-card-front,
.jp-card.jp-card-identified .jp-card-back {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}
.jp-card.jp-card-identified .jp-card-front:before,
.jp-card.jp-card-identified .jp-card-back:before {
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
  background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 30% 30%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 70% 70%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 90% 20%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 15% 80%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(
            255,
            255,
            255,
            0.2
          )
          70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 30% 30%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 70% 70%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 90% 20%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-radial-gradient(
      circle at 15% 80%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    linear-gradient(
      -25deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.2) 70%,
      rgba(255, 255, 255, 0) 90%
    );
  opacity: 1;
}
.jp-card.jp-card-identified .jp-card-front .jp-card-logo,
.jp-card.jp-card-identified .jp-card-back .jp-card-logo {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
}
.jp-card.jp-card-identified.no-radial-gradient .jp-card-front:before,
.jp-card.jp-card-identified.no-radial-gradient .jp-card-back:before {
  background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(
            255,
            255,
            255,
            0.2
          )
          70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    repeating-linear-gradient(
      210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px
    ),
    linear-gradient(
      -25deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.2) 70%,
      rgba(255, 255, 255, 0) 90%
    );
}
