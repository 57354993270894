html,
body,
#root {
  height: 100%;
}

#root > .router {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.jp-card .jp-card-front,
.jp-card .jp-card-back {
  background: #aaa;
}
